.container {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 80%;
  /* padding:50px; */
  /* background-color: #f5f5f5; */
  /* box-shadow: 20px 20px 60px #F19969; */
  box-shadow: -2px 3px 17px 10px rgba(241, 153, 105, 0.1);
  -webkit-box-shadow: -2px 3px 17px 10px rgba(241, 153, 105, 0.1);
  -moz-box-shadow: -2px 3px 17px 10px rgba(241, 153, 105, 0.1);
  /* margin: 0 !important; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  overflow: hidden;
}

#slide {
  width: max-content;
  /* margin-top:50px; */
}
.item {
  width: 18%;
  height: 50%;
  /* max-height: 70%;*/
  background-position: 50% 50%;
  display: inline-block;
  transition: 0.5s;
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 20px;
  box-shadow: 0 1px 18px #505050;
  object-fit: fill;
}
.item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.8); 
  /* z-index: -1;  */
}

.item:nth-child(1),
.item:nth-child(2) {
  left: 0;
  top: 0;
  transform: translate(0, 0);
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
}
.item:nth-child(3) {
  left: 50%;
}
.item:nth-child(4) {
  left: calc(50% + 20%);
}
.item:nth-child(5) {
  left: calc(50% + 40%);
}
.item:nth-child(n + 6) {
  left: calc(50% + 60%);
  opacity: 0;
}
.item .content {
  position: absolute;
  top: 50%;
  left: 100px;
  width: 300px;
  text-align: left;
  padding: 0;
  color: #eee;
  transform: translate(0, -50%);
  display: none;
  font-family: system-ui;
}
.item:nth-child(2) .content {
  display: block;
  z-index: 11111;
}
.item .name {
  font-size: 40px;
  /* font-weight: bold; */
  letter-spacing: 0.3rem;
  opacity: 0;
  animation: showcontent 1s ease-in-out 1 forwards;
}
.item .des {
  margin: 20px 0;
  opacity: 0;
  animation: showcontent 1s ease-in-out 0.3s 1 forwards;
}
.item button {
  background: #fff;
  color: #000;
  padding: 10px 20px;
  border: none;
  opacity: 0;
  animation: showcontent 1s ease-in-out 0.6s 1 forwards;
}
.item button:hover {
  background-color: rgb(80, 80, 80);
}
@keyframes showcontent {
  from {
    opacity: 0;
    transform: translate(0, 100px);
    filter: blur(33px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
    filter: blur(0);
  }
}
.buttons {
  position: absolute;
  bottom: 30px;
  z-index: 222222;
  text-align: center;
  width: 100%;
}
.buttons button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #555;
  background-color: #b1b1b1;
  transition: 0.5s;
}
.buttons button:hover {
  background-color: #bac383;
}

@media (width >= 320px) {
  .content {
    margin-left: -4rem !important;
  }
  .name{
    font-size: 1.5rem !important;
  }
  .des{
    width: 250px !important;
    font-size: 0.8rem !important;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .content {
    margin-left: 6rem !important;
  }
  .name{
    font-size: 1.5rem !important;
  }
  .des{
    width: 300px !important;
    font-size: 1rem !important;
  }
}

@media (width < 768px) {
  .item:not(:nth-child(2)) {
    background-image: none !important;
    box-shadow: none;
  }
  .item::before {
    display: none;
  }
  .buttons button {
    width: 25px;
    height: 25px;
  }
}

@media (width >= 768px) {
  .name{
    font-size: 1.5rem !important;
  }
  .des{
    width: 250px !important;
    font-size: 0.7rem !important;
  }
  .item button {
    width: 4rem !important;
    height: 2rem !important;
  }
}

@media (width >= 1024px) {
  .name{
    font-size: 2rem !important;
  }
  .des{
    width: 350px !important;
    font-size: 1rem !important;
  }
  .item button {
    width: 6rem !important;
    height: 2rem !important;
  }
}

@media (width >= 1440px) {
  .name{
    font-size: 3rem !important;
  }
  .des{
    width: 500px !important;
    font-size: 1.5rem !important;
  }
  .item button {
    width: 8rem !important;
    height: 4rem !important;
  }
}

@media (width >= 2560px) {
  .name{
    font-size: 4rem !important;
  }
  .des{
    width: 600px !important;
    font-size: 2rem !important;
  }
}
