@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 12px;
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, white);
  border-radius: 6px;
}

/* Custom Font */
@font-face {
  font-family: "Methanerse";
  src: url("../public/fonts/methanerse.ttf") format("truetype");
}

/* Background of the whole webpage*/
.bg-star {
  background-image: url("./assets/darkplainstar.jpg");
  background-size: cover;
  background-attachment: fixed;
}

/* Styling for every components title */
.component_title {
  letter-spacing: 0.4rem;
}

@media (width >= 2560px) {
  .component_title {
    font-size: 5rem !important;
  }
}
@media (width >= 1440px) {
  .component_title {
    font-size: 4rem !important;
  }
}
@media (width >= 1024px) {
  .component_title {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .component_title {
    font-size: 2rem !important;
  }
}
@media (width >= 320px) {
  .component_title {
    font-size: 24px !important;
  }
}

/* Achievements Component*/
@keyframes slideFromLeft {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.animate-slide-from-left {
  animation: slideFromLeft 3s ease-in;
}

/* Core */
.customTab:hover {
  background-color: transparent !important;
  color: #fff;
  border-bottom: 1px solid #fff;
}

/* Landing Page */
@media (width <= 425px) {
  ul{
    margin-top: 0.1rem !important;
    gap: 0.3rem !important;
    margin-right: -8rem !important;
  }
  .antarikshLogo{
    margin-left: -3rem !important;
  }
}   

@media (width <= 540px) {
  ul{
    gap: 0.5rem !important;
    margin-right: -8rem !important;
  }
}

@media (width > 768px) {
  .hamburgerMenu {
    display: hidden !important;
  }
}

@media (width <=768px) {
  .heroText {
    font-size: 2rem !important;
  }
  .antarikshLogo{
    height: 100px !important;
    width: 100px !important;
  }
  ul{
    margin-left: 0 !important;
  }
  .navLinkDisplay {
    display: none !important;
    gap: 0.5 !important;
  }
  .hamburgerMenu {
    display: block !important;
  }
  .customTab{
    display: none !important;
  }
}

@media (width <= 320px) {
  .antarikshLogo{
    height: 60px !important;
    width: 60px !important;
  }
}

@media (width <= 820px) {
  ul{
    margin-left: 4rem !important;
  }
  .antarikshLogo{
    height: 80px !important;
    width: 60px !important;
  }
}

@media (width <= 912px) {
  ul{
    margin-left: 2rem !important;
  }
}
@media (width <= 375px) {
  .hamburgerMenu{
    margin-right: -4rem !important;
  }
}
@media (width <= 414px) {
  .hamburgerMenu{
    margin-right: -6rem !important;
  }
}

@media (width <= 1024px) {
  ul{
    margin-left: 2rem !important;
  }
}
@media (width <= 1280px) {
  ul{
    margin-left: -10rem !important;
  }
}
.navMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

#map {
  width: 35%;
  height: 45vh; /* Takes 90% of the viewport height */
  margin: 0 auto;
  border: 5px solid #fff;
}
