.carousel_elastic {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    margin-bottom: 2rem;
    padding: 0 2rem;
}

.carousel_head {
    font-size: 20.5rem;
    color: white;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

button.rec-dot {
    background-color: white;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
    box-shadow: 0 0 3px 2px rgba(230, 167, 133, 0.4);
}

.custom_icon {
    color: white;
    font-size: 2.6rem;
    transition: all 0.25s ease-in-out;
    border-radius: 50%;
}

.custom_icon:hover {
    box-shadow: 0px 0px 10px 4px white;
    transform: scale(1.1);
}

.item_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55vh;
    width: 40vw;
    margin: 0 15px;
    transition: all 0.25s ease-in-out;
}

.item_img:hover {
    transform: scale(1.03);
}

.item_set {
    margin-top: 2rem;
}

.item_head {
    color: white;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
}

@media only screen and (max-width: 1200px) {
    .item_img {
        width: 38vw;
        height: 40vh;
    }

    .carousel_elastic {
        height: 50vh;
    }
}

@media only screen and (max-width: 1100px) {
    .item_img {
        width: 38vw;
        height: 35vh;
    }

    .carousel_elastic {
        height: 60vh;
    }
}

@media only screen and (max-width: 900px) {
    .item_img {
        width: 35vw;
        height: 30vh;
    }

    .carousel_elastic {
        height: 50vh;
    }
}


@media only screen and (max-width: 720px) {
    .item_img {
        width: 60vw;
        height: 30vh;
    }

    .carousel_elastic {
        height: 55vh;
    }
}

@media only screen and (max-width: 525px) {
    .item_img {
        width: 55vw;
        height: 30vh;
    }

    .carousel_elastic {
        height: 35vh;
    }
}

@media only screen and (max-width: 500px) {
    .carousel_head {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 450px) {
    .item_head {
        font-size: 1.0rem;
    }
}

@media only screen and (max-width: 420px) {
    .item_img {
        width: 50vw;
        height: 30vh;
    }

    .carousel_elastic {
        height: 35vh;
    }

    .carousel_head {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .item_head {
        font-size: 1rem;
    }

    .item_img {
        width: 45vw;
        height: 25vh;
    }

    .carousel_elastic {
        height: 30vh;
    }
}

@media only screen and (max-width: 354px) {
    .item_head {
        font-size: 1rem;
    }
}