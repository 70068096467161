#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  /* background: #eee; */
  /* font-family: Helvetica Neue, Helvetica, Arial, sans-serif; */
  font-size: 15px;
  color:#000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination{
  background-image: url(../../assets/click_and_drag.png);
  background-repeat: no-repeat;
  width: 60px !important;
  height: 60px;
  
}

.swiper-pagination-bullet{
  opacity: 0;
}